export default [
  {
    id: '170-0104',
    corePartnerOffers: [
      601
    ],
    partnerOfferCount: 1000,

    /* временное решение */
    mainImage: 'https://notion-emojis.s3-us-west-2.amazonaws.com/prod/svg-twitter/1faa3.svg',
    amount: 5600,
    name: 'Складное силиконовое ведро',
    price: 199860,
    vendor_code: 517620442,

    deliveryDetails: {
      address: 'Donghuamen subdistrict, No. 12, Ministry of Public Security of the People\'s Republic of China Zhengyi Road, Dongcheng district, Beijing',
      postalCode: null
    },

    status: 'draft',
    salesOn: [1, 2, 9],
    created: '2021-04-21T07:31:49.290Z',
    modified: '2022-04-14T07:31:49.290Z',
    departureDate: '2021-04-20T07:31:49.290Z',
    palletsCount: 1,

    docs: ['https://ya.ru'],

    owner: {
      name: 'Shenzhen Dawn Lighting Technology Co., Limited',
      address:
        '3-4f, Xinhanhai Technology Park, Zhenxing Road, Guangming District, Shenzhen, Guangdong, China 518031'
    },

    cancellationReason: 'что-то пошло не так' // max 50
  }
]
