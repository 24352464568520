<template>
  <x-page
    :title="$t('Supply number', [supply.id])"
  >
    <x-card>
      <div class="mb-4" v-for="(key, index) in Object.keys(supply)" :key="index">
        <strong>{{ key }}:</strong> {{ supply[key] }}
      </div>
    </x-card>
  </x-page>
</template>

<script>
import supplies from '@/mock/supplies'

export default {
  name: 'SupplyReview',

  data: () => ({
    supply: {}
  }),

  mounted () {
    this.supply = supplies.find(el => String(el.id) === this.$route.params.supplyId)
  }
}
</script>

<style scoped>

</style>
